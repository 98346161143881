<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="chart">
            <input
                id="display-annotation-labels"
                v-model="displayAnnotationLabelsChecked"
                type="checkbox"
            />

            <label for="display-annotation-labels">
                {{ $t('displayAnnotationLabels') }}
            </label>

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexCharts
                v-if="!isLoading"
                :class="{
                    'chart-display-annotation-labels': displayAnnotationLabelsChecked,
                }"
                height="420px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexCharts from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

export default {
    name: 'AssetChartsView',
    components: {
        ApexCharts,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            displayAnnotationLabelsChecked: false,
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    stacked: false,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                    },
                },
                annotations: {
                    xaxis: [],
                },
                plotOptions: {
                    line: {
                        curve: 'smooth',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                    style: 'full',
                },
                yaxis: {
                    title: {
                        text: this.$i18n.t('fillLevel'),
                    },
                    min: 0,
                    max: 100,
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        converter() {
            return this.$route.name === 'sbbbinchart'
                ? measurementHelper.convertToSbbBinLevel
                : value => parseInt(value * 100)
        },
        fillLevelSensorDataKey() {
            return this.$route.name === 'sbbbinchart'
                ? 'distance'
                : 'fill_level'
        },
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        updateYAxis() {
            this.chartOptions.yaxis.min = this.getYMinValue()
            this.chartOptions.yaxis.max = this.getYMaxValue()
        },
        async load() {
            this.isLoading = true
            const results = await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        setData(results) {
            const fillLevelData = results
                .filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        this.fillLevelSensorDataKey
                    )
                )
                .map(item => [
                    item.timestamp,
                    this.converter(
                        item.sensor_data[this.fillLevelSensorDataKey]
                    ),
                ])

            this.series = [
                {
                    name: this.$t('fillLevel'),
                    data: fillLevelData,
                },
            ]

            const accelData = results.filter(item =>
                Object.prototype.hasOwnProperty.call(
                    item.sensor_data,
                    'accel_y'
                )
            )

            this.chartOptions.annotations.xaxis = accelData.map(
                (item, index) => {
                    return {
                        x: new Date(item.timestamp).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#fdae61',
                        label: {
                            borderColor: '#fdae61',
                            position: 'top',
                            orientation: 'horizontal',
                            // distribute labels across y-axis to reduce overlapping.
                            // The first label (index 0) should start at offset 25 to avoid overlapping the toolbar.
                            // There is space for 13 labels,
                            // therefore use the modulo operator to not fall outside the canvas.
                            offsetY: 25 + ((index * 25) % (13 * 25)),
                            style: {
                                color: '#fff',
                                background: '#fdae61',
                                fontSize: '14px',
                            },
                            text:
                                this.$i18n.t('collection') +
                                ': ' +
                                moment(item.timestamp).format(
                                    'DD.MM.YYYY HH:mm'
                                ),
                        },
                    }
                }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "fillLevel": "Fill level (%)",
        "collection": "Collection",
        "displayAnnotationLabels": "Display collection dates"
    },
    "de": {
        "fillLevel": "Füllstand (%)",
        "collection": "Leerung",
        "displayAnnotationLabels": "Daten der Leerungen anzeigen"
    },
    "fr": {
        "fillLevel": "Niveau de remplissage (%)",
        "collection": "Collection",
        "displayAnnotationLabels": "Afficher les dates de collection"
    },
    "it": {
        "fillLevel": "Riempimento (%)",
        "collection": "Svuotamento",
        "displayAnnotationLabels": "Visualizzazione dei dati dei vuoti"
    }
}
</i18n>

<style lang="scss">
.chart {
    .apexcharts-xaxis-annotations rect,
    .apexcharts-xaxis-annotations text {
        visibility: hidden;
    }

    .chart-display-annotation-labels .apexcharts-xaxis-annotations rect,
    .chart-display-annotation-labels .apexcharts-xaxis-annotations text {
        visibility: visible;
    }
}
</style>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
    @include respond-to('for-tablet-down') {
        display: block;
    }
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
    @include respond-to('for-tablet-down') {
        border: none;
    }
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;

    @include respond-to('for-tablet-down') {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
